/* --------------------------------------------------------
Style Sheet for the Editor.js component

author: Justin Lee
e-mail: justindelladam@live.com
github: https://github.com/realJustinLee

CONTENTS:
- Editor controls
----------------------------------------------------------*/

.flex-container {
    display: flex;
    flex-wrap: nowrap;
}

.numeric-input {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
}

.controls {
    position: absolute;
    height: calc(100% - 10px);
    top: 0;
    left: 2px;
    width: calc(100% - 10px);
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;

    color: white;
    font-size: 12px;
    z-index: 10;
}

.control {
    width: 33%;
    margin-left: 2px;
}

.bone-control {
    display: block;
    margin: 10px 2px;
}

.bone-control p {
    margin-bottom: 0;
    color: white;
}

.export {
    width: 99%;
    height: 40px;
    border-radius: 10px;
    background-color: rgba(144, 144, 144, 0.2);
    border: 1px white solid;
    color: white;
    background-color: #00000059;
    cursor: pointer;
    text-align: center;
    vertical-align: center;
    line-height: 38px;
    font-size: 18px;
    margin-bottom: 10px;
}

.export:hover {
    background-color: rgba(97, 218, 251, 0.4);
}