/* --------------------------------------------------------
Style Sheet for the Loader.js component

author: Justin Lee
e-mail: justindelladam@live.com
github: https://github.com/realJustinLee

CONTENTS:
- Animations for the loaders (rotating liag logo)
----------------------------------------------------------*/

.circle {
    width: 30vmin;
    height: 30vmin;
    border-radius: 100%;
    overflow: hidden;
    top: calc(50% - 15vmin);
    left: calc(50% - 15vmin);
    background-color: #282c34;
    animation: scale 2.5s ease-in-out 0s infinite normal forwards;
}

.circle img {
    width: 100%;
    animation: rotation 2.5s ease-in-out 0s infinite normal forwards;
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes scale {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}