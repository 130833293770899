/* --------------------------------------------------------
Style Sheet for the Category.js component

author: Justin Lee
e-mail: justindelladam@live.com
github: https://github.com/realJustinLee

CONTENTS:
- category styling on the left side with the circles and the svg
----------------------------------------------------------*/

.panel {
    background-color: rgba(255, 255, 255, 0);
    width: 25%;
    height: 100%;
}

.right-side {
    width: 80%;
    height: 100%;
}

.left-side {
    width: 20%;
    max-width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.category {
    z-index: 10;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 100%;
    border: none;
    padding-top: 100%;
    box-shadow: 0 0 10px #000000;
    transition: background-color 0.2s;
    transform: translateX(-30px);
    cursor: pointer;
}

.category:hover {
    background-color: rgba(97, 218, 251, 0.4);
}

.selected-category {
    background-color: rgba(97, 218, 251, 0.8);
}

.category img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: inherit;
    width: 100%;
}
