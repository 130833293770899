/* --------------------------------------------------------
Style Sheet for the ForkMeOnGitHub.js component

author: Justin Lee
e-mail: justindelladam@live.com
github: https://github.com/realJustinLee

CONTENTS:
- Fork Me On GitHub Tag
----------------------------------------------------------*/

.bg-theme:before {
    background-color: rgba(97, 218, 251, 0.5);
    transition: background-color 0.1s;
    box-shadow: 0 0 10px #61dafb;
}

.bg-theme:hover:before {
    background-color: #61dafb;
    box-shadow: 0 0 20px #61dafb;
}

.svg-top-left {
    transition: color, fill 0.1s;
    fill: rgba(97, 218, 251, 0.1);
    color: #61dafbdd;
    position: absolute;
    top: 0;
    border: 0;
    left: 0;
    transform: scale(-1, 1);
}

.svg-top-left:hover {
    fill: #61dafb;
    color: #282c34;
}

.svg-transform {
    transform-origin: 130px 106px;
}

.github-corner:hover .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
}

@keyframes octocat-wave {
    0%, 100% {
        transform: rotate(0)
    }
    20%, 60% {
        transform: rotate(-25deg)
    }
    40%, 80% {
        transform: rotate(10deg)
    }
}

@media (max-width: 500px) {
    .github-corner:hover .octo-arm {
        animation: none
    }

    .github-corner .octo-arm {
        animation: octocat-wave 560ms ease-in-out
    }
}
