/* --------------------------------------------------------
Style Sheet for the Buttons.js component

author: Justin Lee
e-mail: justindelladam@live.com
github: https://github.com/realJustinLee

CONTENTS:
- buttons styling for `download as STL`
----------------------------------------------------------*/

.buttons {
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 10px #000000;
    transition: background-color 0.1s;
    padding: 10px;
    text-align: center;
    border-radius: 0.5em;
    color: white;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    line-height: 30px;
    font-size: 20px;
    left: 160px;
}

.buttons:hover {
    background-color: rgba(97, 218, 251, 0.5);
    box-shadow: 0 0 10px #61dafb80;
}

#download {
    bottom: 80px;
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
