/* --------------------------------------------------------
Style Sheet for the all the component

author: Justin Lee
e-mail: justindelladam@live.com
github: https://github.com/realJustinLee

CONTENTS:
- main classes that are used almost everywhere
----------------------------------------------------------*/

.App {
    text-align: center;
}

.App-logo {
    height: 30vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.abs {
    position: absolute;
}

.top {
    top: 0;
}

.bottom {
    bottom: 0;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.white {
    color: white;
}

.full-screen-message {
    text-align: center;
    width: 90%;
    padding: 20px;
}

.smart-phone img {
    width: 100px;
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dark-screen {
    width: 100%;
    height: 100%;
    background-color: rgba(40, 44, 52, 0.8);
    z-index: 5000;
}

.my-typed {
    overflow-wrap: normal;
}

textarea, select, input, button {
    outline: none;
}