/* --------------------------------------------------------
Style Sheet for the Popup.js component

author: Justin Lee
e-mail: justindelladam@live.com
github: https://github.com/realJustinLee

CONTENTS:
- styles for popup
----------------------------------------------------------*/

.screen {
    width: 100%;
    height: 100%;
    background-color: rgba(40, 44, 52, 0.5);
    z-index: 5000;
}

.popup {
    height: 350px;
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #282c34;
    box-shadow: 0 0 50px #07070760;
    border-radius: 10px;
    color: white;
}

.message {
    font-size: 15px;
    font-weight: 700;
    width: calc(100%);
    height: 100px;
    text-align: center;
    vertical-align: center;
    transform: translate(-50%, -50%);
}

.message p {
    width: 100%;
    margin: 0;
    position: absolute;
    left: 50%;
}

.message p a {
    color: #61dafb;
}

.img-container {
    text-align: left;
    width: 50%;
    height: 50%;
    margin-left: 25%;
    padding-top: 50px;
    padding-bottom: 100px;
    animation: scale 2.5s ease-in-out 0s infinite normal forwards;
}

.info-logo {
    animation: App-logo-spin infinite 8s linear;
    pointer-events: none;
}

.cross {
    top: 10px;
    left: 10px;
    cursor: pointer;
    font-size: 20px;
}

.cross:hover {
    color: #61dafb;
}