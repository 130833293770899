/* --------------------------------------------------------
Style Sheet for the Name.js component

author: Justin Lee
e-mail: justindelladam@live.com
github: https://github.com/realJustinLee

CONTENTS:
- Name of the Avatar
----------------------------------------------------------*/

.name {
    font-size: 40px;
    margin: 50px 50px 50px 150px;
    padding: 5px 15px 5px 15px;
    color: white;
    transition: background-color 0.1s;
    background-color: rgba(97, 218, 251, 0.1);
    box-shadow: 0 0 10px rgba(97, 218, 251, 0.2);
    border-radius: 10px;
}

.name:hover {
    background-color: rgba(97, 218, 251, 0.5);
    box-shadow: 0 0 10px #61dafb80;
}

[contenteditable]:focus {
    outline: 0 solid transparent;
}